<template>
  <div>
    <Listado
      :origen-estado="filtroEstado"
      :columna-fecha="columna"
    />
  </div>
</template>
<script>
import Listado from '@/views/postes/migracionPuntos/Listado.vue'

export default {
  components: {
    Listado,
  },
  data() {
    return {
      columna: {
        label: 'Fecha de Instalación',
        field: 'fechaInstalacion',
        tdClass: 'text-center',
        thClass: 'vgt-center-align',
      },
      filtroEstado: {
        origen: 'INSTALACION',
        estado: 'INSTALACION',
      },
    }
  },
}
</script>
